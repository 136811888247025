import React from 'react';
import Link from 'next/link';
import { Button, Card, Col, Row, Space } from 'antd';

import Logo from '~/components/general/logo';

export default function UnauthorizedPageHeader() {

    return (
        <Row justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
                <Card size="small" bordered={false} style={{ backgroundColor: 'transparent' }}>
                    <Row align="middle" justify="center">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} align="left">
                            <Logo />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} align="right">
                            <Space size="small">
                                {/* 
                                <Button type="danger" size="large">
                                    <Link href="/postanad" passHref>
                                        <a target="_self" rel="noreferrer">Post an Ad</a>
                                    </Link>
                                </Button>
                                */ }
                                <Button type="danger" size="large">
                                    <Link href="/pricing" passHref>
                                        <a target="_blank" rel="noreferrer">Pricing</a>
                                    </Link>
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );

}