import React from 'react';
import Link from 'next/link';
import { Button, Col, Row, Layout } from 'antd';

const { Footer } = Layout;

export default function UnauthorizedPageFooter() {

    return (
        <Row align="middle" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
                <Footer style={{ backgroundColor: 'transparent' }}>
                    <Button size="small" type="link">
                        <Link href="/aboutus" passHref>
                            <a target="_blank" rel="noreferrer">About us</a>
                        </Link>
                    </Button>
                    <Button size="small" type="link">
                        <Link href="/cookiepolicy" passHref>
                            <a target="_blank" rel="noreferrer">Cookie Policy</a>
                        </Link>
                    </Button>
                    <Button size="small" type="link">
                        <Link href="/privacypolicy" passHref>
                            <a target="_blank" rel="noreferrer">Privacy Policy</a>
                        </Link>
                    </Button>
                    <Button size="small" type="link">
                        <Link href="/termsofuse" passHref>
                            <a target="_blank" rel="noreferrer">Terms of Use</a>
                        </Link>
                    </Button>
                    <Button size="small" type="link">
                        <Link href="/refundpolicy" passHref>
                            <a target="_blank" rel="noreferrer">Refund Policy</a>
                        </Link>
                    </Button>
                    <Button size="small" type="link">
                        <Link href="/faq" passHref>
                            <a target="_blank" rel="noreferrer">FAQ&apos;s</a>
                        </Link>
                    </Button>
                    <Button size="small" type="link">
                        <Link href="/contactus" passHref>
                            <a target="_blank" rel="noreferrer">Contact us</a>
                        </Link>
                    </Button>
                </Footer>
            </Col>
        </Row>
    );

}