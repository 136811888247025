import React, { useContext, useState, useEffect } from 'react';
import Router from 'next/router';
import Link from 'next/link';

import { Alert, Button, Card, Col, Divider, List, Modal, Row, Image, Typography, Space } from 'antd';
import { HeartFilled, MailFilled, PhoneFilled } from '@ant-design/icons';

import UnauthorizedPageWrapper from '~/components/wrapper/unauthorizedpagewrapper';
import UnauthorizedPageHeader from '~/components/wrapper/unauthorizedpageheader';
import UnauthorizedPageFooter from '~/components/wrapper/unauthorizedpagefooter';

import { DisclaimerInfoContext } from '~/components/DisclaimerInfoContext';
import { UserInfoContext } from '~/components/UserInfoContext';

const { Title, Text, Paragraph } = Typography;

Index.title = "Home"
export default function Index() {

  const context = useContext(UserInfoContext);

  const disclaimerContext = useContext(DisclaimerInfoContext);

  let [showDisclaimer, setShowDisclaimer] = useState(false);

  const [isUserAuthorized, setUserAuthorized] = useState(false);

  useEffect(() => {
    if (!Boolean(disclaimerContext.disclaimerAccepted)) {
      setShowDisclaimer(true);
    }
  }, [disclaimerContext]);

  function acceptDisclaimer() {
    disclaimerContext.acceptDisclaimer();
    setShowDisclaimer(false);
  }

  useEffect(() => {
    setUserAuthorized(context && context.userInfo && context.userInfo.isAuthorized);
  }, [context]);

  useEffect(() => {
    if (isUserAuthorized) {
      Router.push('/home/feed');
    }
  }, [isUserAuthorized]);

  function login() {
    Router.push("/login");
  }

  function register() {
    Router.push("/register");
  }

  return (
    <UnauthorizedPageWrapper>
      <div style={{ 'paddingBottom': '10vh' }}>

        <Modal open={showDisclaimer} closable={false} style={{ top: 20 }} footer={null}>
          <Row align="middle" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
              <Title level={5}>Disclaimer</Title>
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="left">
              <Paragraph>Hi there,</Paragraph>
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="left">
              <Paragraph>Welcome to fastrbid, the platform for networking buyers and sellers.</Paragraph>
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="left">
              <Paragraph>Keep in mind that any deals with the sellers is under your own responsibility. We will not be responsible before and after the sale and purchase agreement is completed.</Paragraph>
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="left">
              <Paragraph>fastrbid is just an advertising platform. If you find that the seller or buyer has acted in a fraudulent manner (up to and including posting misleading ads), immediately report it to us.</Paragraph>
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="left">
              <Paragraph>Examples of scams are:</Paragraph>
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="left">
              <ol type="1">
                <li>Listing presented during viewing are not the same as advertised.</li>
                <li>Specs are not as described.</li>
                <li>The existence of the company</li>
              </ol>
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="left">
              <Paragraph>Thank you.</Paragraph>
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
              <Button type="danger" size="small" onClick={acceptDisclaimer}>I&apos;ve read and understood</Button>
            </Col>
          </Row>
        </Modal>

        <Row align="middle" justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16} align="center">
            <UnauthorizedPageHeader />
          </Col>
        </Row>

        <Row align="middle" justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16} align="center">
            <Row align="middle" justify="center">
              <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24} align="center">
                <Card bordered={false} style={{ background: 'transparent' }}>
                  <Title level={2}>Zero Commission - Zero Buyer Fees - Zero Risk</Title>
                  <Title level={2}>Win-Win for Buyers & Sellers</Title>
                  <Title level={2}>Easy to Use and Comprehensive Auction Platform</Title>
                </Card>
              </Col>
            </Row>
            <Row align="middle" justify="center">
              <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} align="center">
                <Card bordered={false} style={{ background: 'transparent' }}>
                  <Title level={5}>Zero Commission - Zero Buyer Fees - Zero Risk</Title>
                  <Title level={5}>Win Win for Buyers & Sellers</Title>
                  <Title level={5}>Easy to Use and Comprehensive Auction Platform</Title>
                </Card>
              </Col>
            </Row>
            <Row align="middle" justify="center">
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
                <Space align="center">
                  <Button type="primary" size="large" shape="round" onClick={login}>Login</Button>
                  <Button type="danger" size="large" shape="round" onClick={register}>Register</Button>
                </Space>
              </Col>
            </Row>

            <Row align="middle" justify="center">
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
                <Card size="small" bordered={false} style={{ background: 'transparent' }}>
                  <Alert message={<Text strong>Fastrbid is free to use. Use responsibly.&nbsp;
                    <Link href="/pricing" passHref>
                      <a target="_blank" rel="noreferrer">Condition&apos;s apply.</a>
                    </Link>
                  </Text>} type="info" />
                </Card>
              </Col>
            </Row>

            <Card size="small" bordered={false} style={{ backgroundColor: 'transparent' }}>
              <Row align="middle" justify="center" gutter={[8, 8]}>
                <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} align="center">
                  <Card bordered={false}>
                    <Image rel="prefetch" alt="" preview={false} src={process.env.NEXT_PUBLIC_STATIC_IMAGES_URL + "/zerocommissions.svg"} height="192px" width="192px" />
                    <Card.Meta title="Zero Commission"
                      description={<Text>Fastrbid have premium service payable on a monthly or annual basis (at your choice), with no other commissions or fees to pay.</Text>} />
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} align="center">
                  <Card bordered={false}>
                    <Image rel="prefetch" alt="" preview={false} src={process.env.NEXT_PUBLIC_STATIC_IMAGES_URL + "/nobuyersfee.svg"} height="192px" width="192px" />
                    <Card.Meta title="Zero Buyer Fees"
                      description={<Text>Removing the buyer fee pricing model removes one more barrier to entry and encourages more buyers to come in. No hidden fees.</Text>} />
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} align="center">
                  <Card bordered={false}>
                    <Image rel="prefetch" alt="" preview={false} src={process.env.NEXT_PUBLIC_STATIC_IMAGES_URL + "/buydirectlyfromusers.svg"} height="192px" width="192px" />
                    <Card.Meta title="Zero Risk"
                      description={<Text>Networking with each bidder before they can bid eliminates compliance risk, allowing you to know your buyer, set your terms of sale.</Text>} />
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>

      <Row align="middle" justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
          <div style={{ backgroundColor: '#FFFFFF' }}>
            <Row align="middle" justify="center">
              <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16} align="center">
                <Card bordered={false} style={{ backgroundColor: 'transparent' }}>
                  <Row align="middle" justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
                      <Card bordered={false} style={{ backgroundColor: 'transparent' }}>
                        <Title level={3}>A better way to administer your auction</Title>
                      </Card>
                    </Col>
                  </Row>
                  <Row align="middle" justify="center" gutter={[24, 24]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} align="center">
                      <List bordered={false} size="small" split={false}>
                        <List.Item>
                          <Card hoverable bordered={true} style={{ width: '100%' }}>
                            <List.Item.Meta
                              title={<Title level={5}>Create your private network</Title>}
                              description={<Text>We lets you unlock your business potential by letting you create business networks on a secure platform.</Text>} />
                          </Card>
                        </List.Item>
                        <List.Item>
                          <Card hoverable bordered={true} style={{ width: '100%' }}>
                            <List.Item.Meta
                              title={<Title level={5}>Add your listing</Title>}
                              description={<Text>We puts you in control of your listings. Boost your sales & grow your networks by listing your category today.</Text>} />
                          </Card>
                        </List.Item>
                        <List.Item>
                          <Card hoverable bordered={true} style={{ width: '100%' }}>
                            <List.Item.Meta
                              title={<Title level={5}>Publish your auction</Title>}
                              description={<Text>Set when your auction will take place. Promote your listing to your audience with just a few clicks.</Text>} />
                          </Card>
                        </List.Item>
                      </List>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} align="center">
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
                          <Title level={4}>You Still find it difficult to run auction</Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
                          <Title level={2}>Hire Dedicated Agents</Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
                          <Title level={5}>Dedicated agents are a fixed cost per month, available on demand.</Title>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
                          <Image rel="prefetch" alt="" preview={false} src={process.env.NEXT_PUBLIC_STATIC_IMAGES_URL + "/support.svg"} height="192px" width="192px" />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
                          <Text strong>Give us a call: Monday - Friday from 10am to 5pm. Talk to you soon!</Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
                          <Button icon={<PhoneFilled />} type="link">+91 91763 34412</Button>
                          <Button icon={<MailFilled />} type="link">sales@fastrbid.com</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row align="middle" justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
          <div style={{ backgroundColor: '#f6f7ff', paddingTop: '5vh', paddingBottom: '5vh' }}>
            <Row align="middle" justify="center">
              <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16} align="center">
                <Card bordered={false} style={{ backgroundColor: 'transparent' }}>
                  <Title level={3}>Disposal categories</Title>
                  <Title level={4}>You are at the right place, if you sell or buy anything below through auction</Title>
                </Card>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16} align="center">
                <Card bordered={false} style={{ backgroundColor: 'transparent' }}>
                  <Space wrap>
                    <Button size="small" key="AUTOMOBILESCRAP" type="danger" shape="round">Automobile Scrap</Button>
                    <Button size="small" key="ELECTRICALSCRAP" type="danger" shape="round">Electrical Scrap</Button>
                    <Button size="small" key="METALSCRAP" type="danger" shape="round">Metal Scrap</Button>
                    <Button size="small" key="PLASTICSCRAP" type="danger" shape="round">Plastic Scrap</Button>
                    <Button size="small" key="PAPERSCRAP" type="danger" shape="round">Paper Scrap</Button>
                    <Button size="small" key="REALESTATE" type="danger" shape="round">Real Estate</Button>
                  </Space>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row align='middle' justify='center'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
          <Row align="middle" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16} align="center">
              <Card size="small" bordered={false}>
                <Card bordered={false} style={{ background: 'transparent' }}>
                  <Row align="middle" justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
                      <Title level={3}>How it works - Easy 3 steps to win</Title>
                    </Col>
                  </Row>
                </Card>
                <Row align="middle" justify="center">
                  <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} align="center">
                    <Image rel="prefetch" alt="" preview={false} src={process.env.NEXT_PUBLIC_STATIC_IMAGES_URL + "/register.svg"} height="128px" width="128px" />
                    <Card bordered={false}>
                      <Title level={3} type="success">Register</Title>
                      <Text>No payment required</Text>
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} align="center">
                    <Image rel="prefetch" alt="" preview={false} src={process.env.NEXT_PUBLIC_STATIC_IMAGES_URL + "/bid.svg"} height="128px" width="128px" />
                    <Card bordered={false}>
                      <Title level={3} type="success">Bid</Title>
                      <Text>Bidding is free. Only pay if you win</Text>
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} align="center">
                    <Image rel="prefetch" alt="" preview={false} src={process.env.NEXT_PUBLIC_STATIC_IMAGES_URL + "/win.svg"} height="128px" width="128px" />
                    <Card bordered={false}>
                      <Title level={3} type="success">Win</Title>
                      <Text>Great deals</Text>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row align="middle" justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
          <div style={{ backgroundColor: '#f6f7ff' }}>
            <Row align="middle" justify="center">
              <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16} align="center">
                <UnauthorizedPageFooter />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row align="middle" justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
          <div style={{ backgroundColor: '#f6f7ff' }}>
            <Card bordered={false} style={{ backgroundColor: 'transparent' }}>
              <Row align="middle" justify="center">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
                  <Row align="middle" justify="center" gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
                      <Text strong>End-to-end digital platform <Divider type="vertical" /> Data is private and secure</Text>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
                      <Text strong>100% self-funded and independent.</Text>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
                      <Text strong>Made with {<HeartFilled style={{ color: '#ff453a' }} />} in India</Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </div>
        </Col>
      </Row>

    </UnauthorizedPageWrapper>
  );

}
