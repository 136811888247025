import React from 'react';

import PageWrapper from '~/components/wrapper/pagewrapper';

export default function UnauthorizedPageWrapper(props) {

    return (
        <PageWrapper>
            {props.children}
        </PageWrapper>
    );

}