import React from 'react';

import { Col, Row, Layout } from 'antd';

export default function PageWrapper(props) {

    return (
        <Row align="middle" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">
                <Layout>
                    {props.children}
                </Layout>
            </Col>
        </Row>
    );

}