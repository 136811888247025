import React, { useContext, useEffect, useState } from 'react';
import Router from 'next/router';

import { Button, Col, Row, Typography } from 'antd';

import { ThunderboltFilled } from '@ant-design/icons';

import { UserInfoContext } from '~/components/UserInfoContext';

const { Title } = Typography;

export default function Logo() {

	const context = useContext(UserInfoContext);

	const [isUserAuthorized, setUserAuthorized] = useState(false);

	useEffect(() => {
		setUserAuthorized(context && context.userInfo && context.userInfo.isAuthorized);
	}, [context]);

	function home() {
		if (!isUserAuthorized) {
			Router.push('/');
		} 
	}

	return (
		<Row align="middle">
			<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="left">
				<Button type="danger" size="large" onClick={home}>
					<Title level={4} style={{ color: '#FFFFFF' }}><ThunderboltFilled />fastrbid</Title>
				</Button>
			</Col>
		</Row>
	);

}